<template>
  <v-row
    align="center"
    justify="center"
    :class="tabColor"
    tag="section"
    class="pb-15"
  >
    <div class="wave"></div>
    <v-col cols="12">
      <h3
        class="text-h4 text-md-h3 white--text py-16 text-center section-title"
      >
        みんなの声
      </h3>
    </v-col>
    <v-tabs
      :background-color="tabColor"
      dark
      v-model="tab"
      show-arrows
      grow
      class="mb-10"
    >
      <v-tab
        :href="`#tab-${i}`"
        v-for="(label, i) in item.voicesLabel"
        :key="i"
        >{{ label }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, i) in item.voices"
        :key="i"
        :value="'tab-' + i"
        :class="tabColor"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="(voice, t) in item"
              :key="t"
            >
              <v-card elevation="0" color="grey lighten-4" class="box">
                <v-img :src="voice.img" height="250px" v-if="voice.img"></v-img>
                <v-card-title class="grey lighten-2" v-html="voice.who"
                  ><v-icon class="mr-2"
                    >mdi-comment-processing-outline</v-icon
                  ></v-card-title
                >
                <v-card-text>
                  <p class="pt-3" v-html="voice.voice"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>

<script>
export default {
  name: "Voices",
  props: ["item"],
  data: () => ({
    tabColor: "indigo",
    tab: null,
    tab2: null
  })
};
</script>
