<template>
  <div>
    <PageHeader :header="headData" />
    <v-container
      :class="[item.color, item.textColor]"
      fluid
      v-for="(item, i) in section"
      :key="i"
    >
      <v-row tag="section" justify="center" class="grey lighten-4">
        <v-col cols="12">
          <h2 class="text-h4 text-md-h3 font-weight-bold my-16 text-center">
            {{ item.title }}
          </h2>
        </v-col>
        <v-col cols="12" sm="7" lg="6">
          <div class="mb-16 white rounded-xl pa-6" v-html="item.text"></div>
          <div class="text-right my-12">
            <p>NPO法人 こども達に未来をin湘南</p>
            <p>代表 <strong>松延康隆</strong></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="grey lighten-5 text--secondary" fluid>
      <h2 class="text-h4 text-md-h3 font-weight-bold my-16 text-center">
        団体概要
      </h2>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5">
          <div class="skill-wrapper">
            <dl class="define-row">
              <dt class="define-item">名称</dt>
              <dd class="define-item">NPO法人 こども達に未来を in 湘南</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">代表</dt>
              <dd class="define-item">松延 康隆</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">設立</dt>
              <dd class="define-item">2012年10月11日</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">住所</dt>
              <dd class="define-item">〒251-0032 神奈川県藤沢市片瀬4-1-16</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">電話</dt>
              <dd class="define-item">0466-55-2251</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">Fax</dt>
              <dd class="define-item">0466-22-8721</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">Mail</dt>
              <dd class="define-item">kodomotachimirai@yahoo.co.jp</dd>
            </dl>
          </div>
          <v-row class="grey lighten-5" justify="center" tag="aside">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="amber"
                  light
                  v-bind="attrs"
                  v-on="on"
                  class="my-10"
                  x-large
                >
                  後援・協力
                </v-btn>
              </template>
              <v-card tile>
                <v-toolbar dark color="light-blue darken-4">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>後援・協力</v-toolbar-title>
                </v-toolbar>
                <h3 class="ma-6">
                  多くの方からたくさんのご協力・応援いただきました。（敬称略・順不同）
                </h3>
                <v-card-title
                  ><span class="dialog-subtitle">後援</span></v-card-title
                >
                <v-card class="d-flex flex-wrap mb-5" flat tile>
                  <v-card
                    v-for="(sponsor, n) in sponsors"
                    :key="n"
                    class="pa-4"
                    flat
                  >
                    {{ sponsor }}
                  </v-card>
                </v-card>
                <v-divider></v-divider>
                <v-card-title
                  ><span class="dialog-subtitle">協力</span></v-card-title
                >
                <v-card class="d-flex flex-wrap" flat tile>
                  <v-card
                    v-for="(Cooperation, n) in cooperations"
                    :key="n"
                    class="pa-4"
                    flat
                  >
                    {{ Cooperation }}
                  </v-card>
                </v-card>
                <v-divider></v-divider>
                <v-card-title
                  ><span class="dialog-subtitle"
                    >寄付・カンパ・会費</span
                  ></v-card-title
                >
                <v-card class="d-flex flex-wrap" flat tile>
                  <v-card
                    v-for="(Cooperation, n) in donation"
                    :key="n"
                    class="pa-4"
                    flat
                  >
                    {{ Cooperation }}
                  </v-card>
                </v-card>
                <v-col cols="12" sm="6" class="mt-10 text--secondary">
                  <p>
                    このほか多くの個人、団体、ボランティアグループの皆様から寄付をいただいています。
                    また多くの方々がボランティアとして活動に参加し、お手伝いくださいました。
                  </p>
                  <p>
                    皆様の温かな心強いご支援、ご協力、本当にありがとうございました。
                    今も厳しい状況にある福島のこども達を応援する活動を続けていくため、これからもさまざまな形でのご支援、ご協力をよろしくお願いいたします。
                  </p>
                </v-col>
                <v-btn @click="dialog = false" color="amber" class="ma-4">
                  <v-icon class="mr-1">mdi-close</v-icon>閉じる
                </v-btn>
              </v-card>
            </v-dialog>
          </v-row>
          <h3 class="mt-6 mb-3 h6">各種資料</h3>
          <v-row class="mb-6 report-box">
            <v-col cols="12" md="4">
              <dl>
                <dt class="mb-1">会計・活動報告</dt>
                <dd v-for="(report, i) in annualReports" :key="i">
                  <a :href="`assets/pdf/report/${report}_report_compressed.pdf`"
                    >{{ report }}年度報告書.pdf
                  </a>
                </dd>
              </dl>
            </v-col>
            <v-col cols="12" md="4">
              <dl>
                <dt class="mb-1">その他</dt>
                <dd><a href="assets/pdf/found.pdf">設立趣意書.pdf</a></dd>
                <dd><a href="assets/pdf/aoi.pdf">定款.pdf</a></dd>
                <dd><a href="assets/pdf/officer.pdf">役員名簿.pdf</a></dd>
              </dl>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: { PageHeader },
  name: "About",

  data: () => ({
    dialog: false,
    headData: {
      title: "私たちについて",
      img: "/assets/pic/header/about-header_mini.jpg"
    },
    section: [
      {
        title: "代表あいさつ",
        text: `<p>2011年3月11日に発生した東日本大震災と、それに伴う東電福島第一原発事故から10年が過ぎようとしています。NPO法人「こども達に未来をin湘南」は、原発事故直後にボランティアとして現地福島の惨状を目前にした、前代表・羽田一郎の強固な信念とリーダーシップのもとで、翌年10月に設立されました。</p>
        <p>以来、私たちはNPOの中心事業として、福島県下（主に南相馬市・川俣町）のこども達を毎年春・夏の2回招待し、放射線の影響の少ない湘南の地でのびのびと外遊びを楽しんでもらうための「保養プログラム」を実施してきました。この「保養プログラム」は、多くのボランティアの方々や自治体・大学・寺院・神社・団体など、各方面から様々なご支援・ご協力をいただきながら、2019年までに18回続けられ、800人以上のこども達に3泊4日の湘南での生活を楽しんでもらいました。</p>
        <p>しかし、時とともに原発事故の記憶が風化する一方、事故の収束と被災地の復旧・復興が順調に進んでいるといった情報が氾濫するなかで、この「保養プログラム」を継続することは資金や宿泊施設の確保といった面で次第に困難になってきました。</p>
        <p>さらに、2020年には新型コロナウイルス感染症の拡大という事態が発生し、私たちの「保養プログラム」も実施不可能となり、未だに再開の目途も立たない状態が続いています。
        ただ、私たちが決して忘れてならないことは“原発事故は未だに収束していない”という現実です。現在、私たちは新型コロナウイルス感染症の拡大による２度の緊急事態宣言という未曽有の危機の下にありますが、原発事故直後に出された原子力緊急事態宣言は現在もまだ解除されていないのです。福島県では今なお3万6千人以上の方々が県内外で避難生活を余儀なくされ、増え続ける放射能汚染水の処理や廃炉の前提である溶解核燃料（デブリ）の取り出しなど、問題は山積しています。被災地の方々にとって、大きな地震のたびに原発事故の悪夢におびえるという状況は今でも続いているのです。</p>
        <p>このたび、私たちは保養が不可能な状況下で、私たちにできることを模索するために、NPO法人「こども達に未来をin湘南」の公式ホームページを立ち上げました。この8年間の私たちの全活動を開示するとともに、これまで保養に参加していただいた皆さんに呼びかけて、被災地の皆さんとのウエブ上での交流の場を創り出したいと思います。そして、より多くの方々に被災地の復旧・復興の現状と課題を知っていただき、被災地の人々に対して、今私たちができることは何なのかを考える契機になってほしいと思います。
        本NPOを立ち上げた前代表の羽田一郎は設立主意書で次のように唱っています。</p>
        <p>「私たちは、未来を担うこども達が元気で笑顔で安心して過ごせる場を創り出そうと思います。こども達の笑顔が見られ、それを支える人々の輪が広がること、全ての人が未来に希望を持つことのできること、それが私たちの活動の目的です。」
2016年に病で逝った前代表の遺志を受け継ぎ、私たちは活動を続けてまいります。</p>
<p>どうか私たちの活動にご賛同いただき、ご支援くださいますよう、切にお願い申しあげます。</p>`,
        color: "light-green lighten-1",
        textColor: "text--secondary"
      }
    ],
    groupOverview: {
      name: "NPO法人 こども達に未来を in 湘南",
      establish: "2012年10月",
      address: "〒251-0032 神奈川県藤沢市片瀬4-1-16",
      phone: "0466-55-225",
      fax: "0466-22-8721",
      mail: "kodomotachimirai@yahoo.co.jp",
      rep: "松延康隆"
    },
    annualReports: [
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012"
    ],
    sponsors: [
      "藤沢市",
      "藤沢市教育委員会",
      "藤沢市職員労働組合",
      "藤沢市観光協会",
      "寒川町",
      "寒川町教育委員会",
      "寒川神社",
      "南相馬市",
      "南相馬市教育委員会",
      "鎌倉市",
      "鎌倉市教育委員会",
      "鎌倉観光協会",
      "日本大学生物資源科学部",
      "カトリック藤沢教会",
      "江ノ島電鉄（株）",
      "江ノ島電鉄労働組合",
      "大熊町教育委員会",
      "藤沢市社会福祉協議会",
      "中井町",
      "中井町教育委員会",
      "湘南教職員組合",
      "日蓮宗妙善寺"
    ],
    cooperations: [
      "慶応義塾大学",
      "カトリック藤沢教会",
      "湘南教職員組合",
      "かながわ教職員組合連合",
      "江ノ島電鉄労働組合",
      "あべともこと共に歩む会",
      "チームみつばち",
      "光友会",
      "津島バンド",
      "湘南高校ジャグリングクラブ",
      "ボランティアグループ",
      "牧野青果店",
      "相州神童太鼓",
      "虹の子作業所",
      "看護師グループ「おたんこナース」",
      "やすらぎマジックグループ",
      "生活クラブ生協",
      "生活共同組合パルシステム神奈川ゆめコープ",
      "Shonan 米 Life",
      "オルオルグループ",
      "相模龍王湘南太鼓",
      " 妙善寺",
      "(株)江ノ電バス",
      "新江ノ島水族館 ",
      "昭和観光(株) ",
      "里山グループ ",
      "マジックグループ(やすらぎG, 藤沢教会G)",
      "おなりよさこいきっず",
      "花もめん",
      "NPO・R・WORKSHOP虹の子作業所",
      "バスケット・パフォーマンス(三井優和グループ)",
      "日本大学生物資源科学部(小林信一先生とゼミの皆さん・学生サークル「ラ・サンテ」「ジャック・ボックス」・銀座スエヒロカフェテリア)",
      "堀川網",
      "栄湯湘南館",
      "NPO帆船やまゆり保存会",
      "田淵勇太グループ",
      "南京玉すだれの仲間",
      "俣野ばやし保存会",
      "日蓮宗妙善寺",
      "寒川町職員組合",
      "県立辻堂海浜公園",
      "湯快爽快茅ヶ崎",
      "産学社",
      "いきいき福祉会",
      "トキコプランニング",
      "羽衣会",
      "寒川茂内教室コーラスグループ",
      "キャンパス内銀座スエヒロカフェテリア",
      "NPOさぽーとセンターぴあ(青田由幸)",
      "建長寺",
      "西俣野太鼓保存会",
      "小野和佳",
      "小山(株)神奈川営業所",
      "ラーメンボランティアグループ・ロワール光月堂",
      "藤沢市立白浜養護学校",
      "藤沢市立高砂小学校",
      "藤沢市立鵠南小学校",
      "焼き鳥まつみ",
      "志村牛乳店",
      "湘南ヤクルト(株)",
      "出羽商会",
      "(株)プレシア",
      "サーフ９０茅ヶ崎",
      "銭湯不動湯",
      "湘南営繕協会",
      "藤沢市商工会議所",
      "藤沢市商店会連合会",
      "藤沢市会議員１９の会",
      "エール湘南",
      "堀川網",
      "小野マジックグループ",
      "NPO法人 市民の図書館ふじさわ",
      "特定非営利集団おたすけ隊",
      "セブンイレブン辻堂高砂店",
      "湯花楽秦野店",
      "遊行寺",
      "遊行寺茶屋",
      "gainail salon momona pasuteru",
      "つながっぺ福島"
    ],
    donation: [
      "福祉農園芸Harmony",
      "NPO法人R・WORKSHOP虹の子作業所",
      "おなりよさこいきっず",
      "チームみつばち",
      "神奈川県教職員組合(復興対策委員会)",
      "湘南教職員組合",
      "里山グループ",
      "花もめん",
      "いきいき福祉会",
      "ニコニコ農園",
      "生活クラブ生協東日本震災復興支援まつり実行委員会",
      "カトリック藤沢教会",
      "おたんこナース",
      "俣野ばやし保存会",
      "「神童太鼓」チャリティー舞演会実行委員会",
      "上々台風コンサート実行委員会",
      "プロジェクトM",
      "刺繍サークル「愉快な仲間」",
      "永寿会",
      "福島の子供たちの為のチャリティーコンサート実行委員会",
      "トキコプランニング",
      "日蓮宗妙善寺",
      "魔翁院",
      "エフの会",
      "刺繍サークル「ゆかいな仲間」",
      "「小さき声のカノン」上映実行委員会",
      " 社福「いきいき福祉会」",
      "すずめの会",
      "「日本と原発」上映実行委員会",
      "ラウンドカフェ",
      "東北大震災支援祭り実行委員会事務局",
      "江口友子事務所",
      "中井町",
      "ふじさわ市民白書の会",
      "遊行フォーラム",
      "聖マルコ協会",
      "匿名の方々"
    ]
  })
};
</script>
<style lang="scss" scoped>
.report-box {
  dd {
    padding-bottom: 8px;
  }
}
</style>
